import React from 'react';
import {Card, CardContent, Typography, Grid} from '@material-ui/core';
import CountUp from 'react-countup';
import cx from 'classnames';
import styles from './Cards.module.css';

const Cards = ({data: {confirmed, recovered, deaths, lastUpdate}}) => {
  if(!confirmed) {
    return "Loading...";
  }

  let cardsData = [
    {
      styling: styles.infected,
      title: "Infected",
      value: confirmed.value,
      description: "Number of active cases of COVID-19"
    },
    {
      styling: styles.recovered,
      title: "Recovered",
      value: recovered.value,
      description: "Number of recoveries from COVID-19"
    },
    {
      styling: styles.deaths,
      title: "Deaths",
      value: deaths.value,
      description: "Number of deaths from COVID-19"
    }
  ];

  return (
      <div className={styles.container}>
        <Grid container spacing={3} justify={'center'}>
          {cardsData.map(cardData => (
              <Grid item component={Card} xs={12} md={3} className={cx(styles.card, cardData.styling)}>
                <CardContent>
                  <Typography color={'textSecondary'} gutterBottom>{cardData.title}</Typography>
                  <Typography variant={'h5'}>
                    <CountUp start={0} end={cardData.value} duration={2.5} separator={','}/>
                  </Typography>
                  <Typography color={'textSecondary'}>{new Date(lastUpdate).toDateString()}</Typography>
                  <Typography variant={'body2'}>{cardData.description}</Typography>
                </CardContent>
              </Grid>
          ))}
        </Grid>
      </div>
  );
};

export default Cards;