import Axios from 'axios';

const url = 'https://covid19.mathdro.id/api';

export const fetchData = async (country) => {
  let changeableUrl = country ? `${url}/countries/${country}` : url;

  try {
    const { data: {confirmed, recovered, deaths, lastUpdate} } = await Axios.get(changeableUrl);

    return {confirmed, recovered, deaths, lastUpdate};
  } catch (error) {
    console.log(error);
  }
};

export const fetchDailyData = async () => {
  try {
    const {data} = await Axios.get(`${url}/daily`);

    return data.map(({confirmed, deaths, reportDate}) => {
      return {
        confirmed: confirmed.total,
        deaths: deaths.total,
        date: reportDate
      }
    });
  } catch (e) {

  }
};

export const fetchCountries = async () => {
  try {
    const {data : {countries}} = await Axios.get(`${url}/countries`);

    return countries.map((country) => country.name);
  } catch (e) {
    console.log(e);
  }
};